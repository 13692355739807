/*
  Cette classe est utilisé pour gérer et créer les URLs de la carte.

  lorsqu’on clique sur une categorie, sous categorie, ou une localisation
  l’objet est mis à jour, construit l’url, et la pousse dans le navigateur
 */

export class mapUrl {

  // GETTER / SETTER // Category
  static setSlugCategory(slug) {

    mapUrl._slugCategory = slug

    // déselectionne la subcategorie s'il a lieu.
    mapUrl._slugSubCategory = null

    // déselectionne la loaclisation s'il a lieu.
    mapUrl._slugLocalisation = null

    mapUrl.updateUrl()
  }

  static getSlugCategory() {
    return mapUrl._slugCategory
  }

  static getOnLoadSlugCategory() {
    return mapUrl._onLoadSlugCategory
  }

  static resetOnLoadSlugCategory() {
    mapUrl._onLoadSlugCategory = null
  }

  // GETTER / SETTER // SubCategory
  static setSlugSubCategory(slug) {

    if (typeof mapUrl._slugCategory == 'undefined') return false
    mapUrl._slugSubCategory = slug
    mapUrl._slugLocalisation = null // reinitialise les fils
    mapUrl.updateUrl()
  }

  static getSlugSubCategory() {
    return mapUrl._slugSubCategory
  }

  static getOnLoadSlugSubCategory() {
    return mapUrl._onLoadslugSubCategory
  }

  static resetOnLoadSlugSubCategory() {
    mapUrl._onLoadslugSubCategory == null
  }

  // GETTER / SETTER // SubLocalisation
  static setSlugLocalisation(slug) {
    console.log('aaaaa')
    mapUrl._slugLocalisation = slug
    mapUrl.updateUrl()
  }

  static getSlugLocalisation() {
    return mapUrl._slugLocalisation
  }

  static getOnLoadSlugLocalisation() {
    return mapUrl._onLoadslugLocalisation
  }

  static updateUrl() {

    // recuperer le path
    let currentURL = new URL(window.location)
    let urlPath = currentURL.pathname
    let urlPathParts = urlPath.split('/')
    urlPathParts.shift() // virerle premier slash

    // construction de la nouvelle URL
    let newUrlPath = []
    newUrlPath[0] = mapUrl._slugCategory
    newUrlPath[1] = mapUrl._slugSubCategory
    newUrlPath[2] = mapUrl._slugLocalisation

    let mapSection = newUrlPath.filter(Boolean).join('/')

    // creation de la nouvelle URL en utilisant la base de la précédante et en ajoutant le reste
    let newUrl = '/' + urlPathParts[0] + '/' + mapSection

    var stateObj = { foo: 'bar' }
    history.pushState(stateObj, 'page 2', newUrl)

    mapUrl._setIsOnRoot()
  }

  static parseUrl() {

    // recuperer le path
    let currentURL = new URL(window.location)
    let urlPath = currentURL.pathname
    let urlPathParts = urlPath.split('/')
    urlPathParts.shift() // virer le premier slash
    urlPathParts.shift() // virer le nom de la page de carte

    // charger les propiétés static de l’objet avec les sections d’URL
    if (urlPathParts[0] != undefined && urlPathParts[0] != '') mapUrl._slugCategory = urlPathParts[0]
    if (urlPathParts[1] != undefined && urlPathParts[1] != '') mapUrl._slugSubCategory = urlPathParts[1]
    if (urlPathParts[2] != undefined && urlPathParts[2] != '') mapUrl._slugLocalisation = urlPathParts[2]
  }

  static onLoadInit() {

    // parse l’URL courante
    mapUrl.parseUrl()

    mapUrl._onLoadSlugCategory = mapUrl.getSlugCategory()
    mapUrl._onLoadslugSubCategory = mapUrl.getSlugSubCategory()
    mapUrl._onLoadslugLocalisation = mapUrl.getSlugLocalisation()

    mapUrl._setIsOnRoot()
  }

  static _setIsOnRoot() {

    if ( mapUrl._slugCategory )
      mapUrl._isOnRoot = false
    else
      mapUrl._isOnRoot = true
  }

  static isOnRoot() {

    return mapUrl._isOnRoot
  }
}

// NE PAS APPELER DIRECTEMETN LES PROPRIÉTÉES AVEC UN UNDERSCORE
mapUrl._isOnRoot // Aucune catégorie d'ouverte.

mapUrl._slugCategory // contient le slug de la categorie de base
mapUrl._slugSubCategory // contient le slug de la sous categorie
mapUrl._slugLocalisation // contient le slug du POI

mapUrl._onLoadSlugCategory // contient le slug de la categorie de base lors du chargement de la page, cette propriété ne change pas
mapUrl._onLoadslugSubCategory // contient le slug de la sous categorie lors du chargement de la page, cette propriété ne change pas
mapUrl._onLoadslugLocalisation // contient le slug du POI lors du chargement de la page, cette propriété ne change pas

window.mapUrl = mapUrl