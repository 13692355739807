// |--------------------------------------------------------------------------
// | Importation des scripts
// |--------------------------------------------------------------------------
// |
// | Main.js importe tous les fichiers qu'on a de besoin, ça commence par les
// | node-modules, puis les fichiers propres au projet.
// |--------------------------------------------------------------------------

// Initialisation sur toutes les pages
import $ from 'jquery'
window.jQuery = $
import Observer from './../libraries/observer.min.js'
import Scrollfire from './../libraries/scrollfire.min.js'
import Parallax from './../libraries/parallax.min.js'
import 'jquery.easing'
// Fancybox
import '@fancyapps/fancybox/dist/jquery.fancybox.js'
// Slick Carousel
import 'slick-carousel'
// Calendrier
import 'bootstrap-datepicker'
// Formulaires
import 'jquery-validation'
import 'jquery-mask-plugin'
import 'jquery-validation/dist/additional-methods.js'
import 'devbridge-autocomplete'

import { Home, Generic, News, NewsDetail, Events, Event, Search, Jobs, Job, Contact, Map, Assistant, Sections, SearchByAddress, Schedules, Schedule } from './views.js'

// Assignation de constantes
export const OBSERVER = Observer.getInstance()
export const SCROLLFIRE = Scrollfire.getInstance()
export const PARALLAX = Parallax.getInstance()

  addEventListener('DOMContentLoaded', () => {

  setTimeout(function() {
    $('html').removeClass('chargement')
  }, 100)

  const classes = { Home, Generic, News, NewsDetail, Events, Event, Search, Jobs, Job, Contact, Map, Assistant, Sections, SearchByAddress, Schedules, Schedule }

  $('body').keydown( function(e) {
    var keycode = (e.keyCode ? e.keyCode : e.which)
    if ((e.ctrlKey  && keycode == 13) || (e.metaKey  && keycode == 13)) {
      location = '/administration'
      return false
    }
  })

  let className = document.querySelector('body .main').dataset.view
  let view = new classes[className]()
  view.init()
})
