// |--------------------------------------------------------------------------
// | Popup et sidepanel
// |--------------------------------------------------------------------------
// |
// | Ici on retrouve tout ce qui a attrait aux popups et au sidepanels.
// |--------------------------------------------------------------------------

import Overlay from '../../libraries/overlay.js'
import { OBSERVER } from '../main.js'
import { copyTextToClipboard } from './functions.js'
import { Accordions } from './accordions.js'

// Fonction gérant l'overlay Search
export const overlayDropdown = (root = document) => {

  let dropdown = new Overlay({
    name: 'dropdown',
    create: { close: false },
    click: { buttons: { trigger: '.js-trigger-overlay-dropdown', close: '.js-close-all-overlay, .js-close-overlay-dropdown, .overlay-dropdown__background', switch: '.js-toggle-overlay-search' }},
    animations: {
      selector: '.js-dropdown-container',
      addTransition: true,
      styles: [{ property: 'height', value: '100vh', easing: 'easeInOutCubic' }]
    },
    options: {
      speed: 800,
      root: root
    }
  })

  dropdown.init()
}


// Fonction gérant l'overlay Menu
export const overlayMenu = (root = document) => {
  let menu = new Overlay({
    name: 'menu',
    create: { close: false },
    click: { buttons: { close:'.js-close-overlay,.js-close-overlay-menu', toggle: '.js-toggle-overlay-menu', switch: '.js-toggle-overlay-search' }},
    options: {
      speed: 800,
      goToSelector: 'html, body',
      root: root
    }
  })
  menu.init()
  new Accordions({ containerClass: 'js-accordions-menu', scrollToAccordion: false, root: root })
}


// Fonction gérant l'overlay Search
export const overlaySearch = (root = document) => {
  let search = new Overlay({
    name: 'search',
    click: { buttons: { toggle: '.js-toggle-overlay-search', switch: '.js-trigger-overlay-dropdown, .js-toggle-overlay-menu', close: '.js-close-overlay-search' }},
    animations: {
      addTransition: true,
      styles: [{ property: 'height', value: '100%', easing: 'easeInOutQuart' }]
    },
    events: {
      openComplete: true,
    },
    options: {
      speed: 800,
      goToSelector: 'html, body',
      root: root,
      closeOnResize: false,
    }
  })
  
  search.init()

  addEventListener('onOpenCompleteOverlaySearch', giveFocusToSearch)

  function giveFocusToSearch(){
    root.querySelector('.js-input-to-clear').focus()
  }

  // Desktop
  activateWithKeyUp('#form-search #form-search-input', '#autocomplete', true)
  // Mobile
  activateWithKeyUp('#form-search-overlay #form-search-overlay-input', '#autocomplete', true)

  // Fermer le wrapper result
  $('.resultWrapper').stop(true, false).animate({ height: 'hide' }, { duration: 0 })

  function activateWithKeyUp(input, selector, htmlClass = false) {

    var ajaxRequest = false

    $(input).keyup(function(e) {

      if( ajaxRequest ) {
        // Si une requête est déjà en cours, l'annuler pour faire place à la prochaine
        ajaxRequest.abort()
      }

      var keycode = (e.keyCode ? e.keyCode : e.which)

      if ( keycode != 13 ) {
        if ($(this).val().length >= 4) {

          ajaxRequest = $.request('rechercheAutocomplete::onFetchAutocomplete', {
            data: { q: $(this).val() },
            update: { 'rechercheAutocomplete::autocomplete':'#overlay-search #autocomplete'},
            complete: function (data) {
              // Results loaded.
              ajaxRequest = false
              
              //On affiche l'overlay des suggestions juste si ya des résultats
              if(data.responseJSON && data.responseJSON['rechercheAutocomplete::autocomplete']){
                search.open()
                $('.resultWrapper').stop(true, false).animate({ height: 'show' }, {
                  duration: 800,
                  specialEasing: { height: 'easeInOutQuart' },
                  complete: function() { $('.resultWrapper').css('height', '')}
                })
              }
              else {
                $('.resultWrapper').stop(true, false).animate({ height: 'hide' }, {
                  duration: 800,
                  specialEasing: { height: 'easeInOutQuart' },
                  complete: function() { $('.resultWrapper').css('height', '')}
                })
              }

            },
          })
        } else {
          $('.resultWrapper').stop(true, false).animate({ height: 'hide' }, {
            duration: 800,
            specialEasing: { height: 'easeInOutQuart' },
            complete: function() { $('.resultWrapper').css('height', '')}
          })
        }
      }
    })
  }
}


// Fonction gérant l'overlay du calendrier
export const overlayCalendar = (root = document) => {
  
  let calendar = new Overlay({
    name: 'calendar',
    create: { close: false },
    click: { buttons: { close:'.js-close-overlay,.js-close-overlay-calendar', toggle: '.js-toggle-overlay-calendar', switch: '.js-toggle-overlay-search' }},
    options: {
      speed: 800,
      root: root
    }
  })
  calendar.init()
  window.calendarOverlay = calendar
}


// Fonction gérant l'overlay de partage
export const overlayShare = (root = document) => {

    if (typeof __sharethis__ !== 'undefined' && __sharethis__.config) {
      __sharethis__.init(__sharethis__.config)
    }
  
    let share = new Overlay({
      name: 'share',
      click: {
        buttons: {
          open: '.js-share',
          close: '.close-overlay',
        }
      },
      options: {
        speed: 800,
        root: root
      }
    })
  
    share.init()
  
    $('#copyShareBtn').click( function() {
      var link = $(this).attr('data-url')
      copyTextToClipboard(link)
      copyTextToClipboard(window.location.href)
      alert('L’adresse URL a bien été copiée sur votre presse-papiers.')
      return false
    })
  
    $('.rubric-share').on('click', function(e) {
      window.history.pushState(null, null, '#' + e.currentTarget.dataset.rubric)
      //pour le partage par courriel
      $('#emailShareBtn').attr('href', 'mailto:?Subject=Rouyn-Noranda&body=' + window.location.href)
      //pour le partage par facebook ou twitter
      $('#facebookShareBtn').attr('data-url', window.location.href)
      $('#twitterShareBtn').attr('data-url', window.location.href)
    })
  
    //clean l'url quand on ferme l'overlay de share
    $('.overlay-share__background, .overlay-share__close').on('click', function() {
      window.history.replaceState(null, null, ' ')
      //remettre le partage de la page (sans #)
      $('#emailShareBtn').attr('href', 'mailto:?Subject=Rouyn-Noranda&body=' + window.location.href)
      $('#facebookShareBtn').attr('data-url', window.location.href)
      $('#twitterShareBtn').attr('data-url', window.location.href)
    })
  }


// Fonction gérant l'overlay popup
export function overlayPopup(root = document) {
  if(document.querySelector('[data-overlay="popup"]') !== null){
    let popup = new Overlay({
      name: 'popup',
      create: {
        background: false
      },
      timeout: {
        delay: 300,
      },
      click: {
        buttons: {
          close: '.js-close-overlay-popup'
        }
      },
      options: {
        speed: 800,
        root: root
      }
    })

    popup.init()
  }
}


// Fonction gérant l'overlay de liens rapides
export const overlayFastLinks = (root = document) => {

  let fastLinks = new Overlay({
    name: 'fast-links',
    click: {
      buttons: {
        close:'.js-close-overlay, .js-close-overlay-fast-links',
        toggle: '.js-toggle-overlay-fast-links'
      }
    },
    animations: {
      selector: '.js-fast-links-container',
      styles: [{
        property: 'height',
        value: `100vh`,
        easing: 'easeInOutQuart'
      }]
    },
    options: {
      speed: 800,
      root: root,
      closeOnResize: false,
    }
  })

  fastLinks.init()
}