// |--------------------------------------------------------------------------
// | Formulaires et validation
// |--------------------------------------------------------------------------
// |
// | Validation, messages d'erreur, formatage de champs et divers à propos des
// | formulaires, c'est ici !
// |--------------------------------------------------------------------------


import { OBSERVER } from '../main'
import { formatBytes, isMobile } from './helper'
import $ from 'jquery'


// Ajoute un attribut en considérant si le input est plein ou vide
export const inputsAndTextareaLabel = () => {
  const elementsString = 'input[type="text"], input[type="email"], input[type="search"], textarea',
    formElements = document.querySelectorAll(elementsString),
    formElementsLength = formElements.length
  let i

  for (i = 0; i < formElementsLength; i++){
    formElements[i].parentNode.dataset[formElements[i].tagName.toLowerCase() + 'value'] = formElements[i].value
  }

  const setValue = (e) => e.target.parentNode.dataset[e.target.tagName.toLowerCase() + 'value'] = e.target.value

  OBSERVER.add({ name: 'inputsAndTextareaLabel', event: 'input', target: elementsString, function: setValue })
  OBSERVER.on('inputsAndTextareaLabel')
}


// Permet de supprimer le contenu d'un input
export const clearInput = (root = document) => {
  const onClick = (e) => {
    let input = e.currentTarget.parentNode.querySelector('.js-input-to-clear')
    input.value = ''
    input.focus()
    input.parentElement.dataset.inputvalue = ''
    input.classList.remove('valid')
  }

  OBSERVER.add({
    name: 'clearInput',
    event: 'click',
    target: '.js-clear-input',
    root: root,
    function: onClick
  })

  OBSERVER.on('clearInput')
}


// Modification de la hauteur d'un textarea selon son contenu
export const textareaHeight = (root = document) => {
  const onInput = (e) => {
    e.currentTarget.style.height = '5px'
    e.currentTarget.style.height = `${e.currentTarget.scrollHeight <= 43 ? 43 : e.currentTarget.scrollHeight}px`
  }

  OBSERVER.add({
    name: 'textareaHeight',
    event: 'input',
    target: 'textarea',
    root: root,
    function: onInput
  })

  OBSERVER.on('textareaHeight')
}


// Permet de changer le label des input files
export const fileUpload = (root = document) => {
  const clear = (i, element, currentLabelText) => {
    element.value = ''
    element.nextElementSibling.querySelector('.js-file-text').innerText = currentLabelText
    element.parentNode.dataset['file'] = ''
    OBSERVER.off(`clear${i}`)
  }

  const changeLabel = (e) => {
    const self = e
    const label = e.currentTarget.nextElementSibling.querySelector('.js-file-text')
    const currentLabelText = label.innerText
    let i, newLabel = '', fileLength = e.currentTarget.files.length

    if ('files' in e.currentTarget) {
      if (fileLength !== 0) {
        for (i=0; i<fileLength; i++) {
          let file = e.currentTarget.files[i]
          newLabel += `${(i+1)}. `

          if ('name' in file) newLabel += `fichier: ${file.name}, `
          if ('size' in file) newLabel += `poids: ${formatBytes(file.size)} \n`

          const onClear = () => clear(i, self.target, currentLabelText)

          OBSERVER.add({
            name: `clear${i}`,
            event: 'click',
            target: e.currentTarget.previousElementSibling,
            root: root,
            function: onClear
          })

          OBSERVER.on(`clear${i}`)
        }
        e.currentTarget.parentNode.dataset['file'] = newLabel
        label.innerText = newLabel
      }
    }
  }

  OBSERVER.add({
    name: 'fileUpload',
    event: 'change',
    target: 'input[type=file]',
    root: root,
    function: changeLabel
  })

  OBSERVER.on('fileUpload')
}


// Création du custom select (doc: https://github.com/pytesNET/tail.select)
export const select = () => {
  if (!isMobile())
    tail.select('select', { animate: false })

  // Ajouter les images dans les options du Tail Select
  let selectBoxes, selectBoxesLength, i, j
  selectBoxes = document.querySelectorAll('.tail-select')
  selectBoxesLength = selectBoxes.length

  for (i=0; i<selectBoxesLength; i++){ //Looper dans chaques boites de sélections Tail Select
    var tailSelectItems = selectBoxes[i].querySelectorAll('.dropdown-option')
    var nativeSelect = selectBoxes[i].previousElementSibling
    var nativeSelectItems = nativeSelect.querySelectorAll('option:not(:first-child)')

    // Ajouter l'icone en symbole
    var svgElem = document.createElementNS('http://www.w3.org/2000/svg', 'svg'),
    useElem = document.createElementNS('http://www.w3.org/2000/svg', 'use')
    useElem.setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', '/themes/init-theme/assets/medias/images/icons/symbols.svg#ico-pointer')
    svgElem.appendChild(useElem);
    //selectBoxes[i].querySelector('.select-label').appendChild(svgElem)
    selectBoxes[i].appendChild(svgElem)

    for (j=0; j<nativeSelectItems.length; j++){ //Looper dans chaques item du Tail Select
      var imgPath = nativeSelectItems[j].dataset.image
      if((typeof imgPath !== "undefined") && imgPath !='' ){
          var newImage = document.createElement('img')
          newImage.src = imgPath
          tailSelectItems[j].classList.add('has-image')
          tailSelectItems[j].appendChild(newImage)
      }
    }
  }

  // Gérer le changement de choix dans le select pour mettre l'image dans la boite du résultat sélectionné
  // Pas utilisé pour le moment
  /*
  OBSERVER.add({
    name: 'tailSelectChange',
    event: 'change',
    root: document,
    target: '.field--select select',
    function: tailSelectChange
  })
  OBSERVER.on('tailSelectChange')

  function tailSelectChange(e){
    var tailActive = e.target.parentElement.querySelector('.label-inner')

    if(tailActive){
      var selectedTailItem = e.target.parentElement.querySelectorAll('.tail-select .dropdown-option')[e.target.selectedIndex-1]
      if(selectedTailItem.classList.contains('has-image')){
        var newImage = document.createElement('img')
        newImage.src = selectedTailItem.querySelector('img').src
        tailActive.appendChild(newImage)
      }
    }

  }
  */
}

// LES VALIDATIONS __________
// Fonction exécutant la validation de chaque formulaire
export function formValidation(form, submit, rules, messages, handler) {
  $.validator.setDefaults({
    ignore: []
  })
  $(form).validate({
    debug: false,
    errorElement: 'div',
    focusInvalid: false,
    invalidHandler: function (form, validator) {
      if (!validator.numberOfInvalids())
        // eslint-disable-next-line semi
        return;

      //console.log(validator)

      const nbProjectError = validator.errorList.find(item => {
        return item.element.name === 'nbProject'
      })

      if(nbProjectError === undefined) {
        $('html, body').animate({
          scrollTop: $(validator.errorList[0].element).offset().top - 50
        }, 500)
      }
    },
    rules: rules,
    messages: messages,
    errorPlacement: function (error, element) {

      console.log(element)

      switch(element[0].getAttribute('type')) {
        // Exeption pour les radio buttons ou checkbox
        case 'radio':
        case 'checkbox':
          error.appendTo(element.parent().parent().parent().find('.js-form-error'))
          break;
        // Types texts et autres
        default:
          error.appendTo(element.parent().find('.js-form-error'))
      }


    },
    submitHandler: function () {
      if (typeof handler != 'undefined') {
        handler()
        return false
      }
      return true
    }
  })

  // Recaptcha recherche
  if (form == '#form-search' || form == '#form-search-page' || form == '#form-search-overlay') {
    $(submit).click(function(){
      grecaptcha.ready(function() {
        grecaptcha.execute('6LdVGsUqAAAAAL0XfTckVa9Qd6KYemeFoVPvVl9E', {action: 'submit'}).then( function(token) {
          $.ajax({
            url: '/blanko/recherche/captcha',
            data: {token: token},
          }).done(function(result){
            if (result.success) {
              $(form).submit();
            } else {
              alert(result.message);
            }
          });
        });
      });
      return false;
    })
  } else {
    $(submit).click(function () {
      $(form).submit()
    })
  }
}


export function validationEmail() {
  $.validator.addMethod('courriel', function(value, element) {
    return this.optional(element) || /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/.test(value)
  })
}

export function formatPhone(element) {
  $(element).mask('000 000-0000')
}

// LES MESSAGES
// Fonction contenant tous les messages
export function getMessages() {
  if ($('html')[0].lang == 'en') {
    return {
      required: 'This field is required.',
      select: 'Please chose an option.',
      email: 'Please enter a valid email address.',
      phone: 'Please enter a valid phone number.',
      postale: 'Please enter a valid ZIP code.',
      date: 'Please enter a valid date.',
      accept: 'The file must be a document pdf.',
      file: 'Please provide a adequate file.'
    }
  } else {
    return {
      required: 'Ce champ est obligatoire.',
      select: 'Veuillez sélectionner une option.',
      email: 'Veuillez fournir une adresse électronique valide.',
      phone: 'Veuillez fournir un numéro de téléphone valide.',
      postale: 'Veuillez fournir un code postal valide.',
      date: 'Veuillez fournir une date valide.',
      accept: 'Le fichier doit être un document pdf.',
      file: 'Veuillez lier le fichier adéquat pour ce champ.'
    }
  }
}

// Validation du formulaire de example'
/*export function initSearchForm() {
  const checkEmptyOnChange = (e) => {
    let form = e.currentTarget.parentNode
    console.log('entrééé')
  }

  OBSERVER.add({
    name: 'checkEmpty',
    event: 'change',
    target: '.js-search-form input',
    function: checkEmptyOnChange
  })

  OBSERVER.on('checkEmpty')
}*/


// Validation de la recherche
export function formSearch(form, submit) {
  var m = getMessages()
  var rules = {
    q:     { required: true },
  }
  var messages = {
    q:     { required: m.required },
  }

  formValidation(form, submit, rules, messages)

  //SpeechToText
  OBSERVER.add({
    name: 'speechTotext',
    event: 'click',
    target: '.search-form__microphone',
    function: onClick
  })

  OBSERVER.on('speechTotext')

  // Show/hide micro selon SpeechRecognition is supproter par le navigateur
  if ('SpeechRecognition' in window || 'webkitSpeechRecognition' in window) {
    // speech recognition API supported
    if (isMobile()) {
      $('.search-form__microphone').show()
    } else {
      $('.search-form__microphone').hide()
    }
  } else {
    // speech recognition API not supported
    $('.search-form__microphone').hide()
  }

  function onClick(e) {
    let SpeechRecognition = SpeechRecognition || webkitSpeechRecognition
    let recognition = new SpeechRecognition()
    recognition.lang = 'fr'

    recognition.onstart = function() {
      document.documentElement.style.setProperty('--microphone-color', 'red')
    }

    recognition.onspeechend = function() {
      recognition.stop()
      document.documentElement.style.setProperty('--microphone-color', '')
    }

    recognition.onresult = function(event) {
      let transcript = event.results[0][0].transcript;
      document.querySelector('#form-search-input').value = transcript.charAt(0).toUpperCase() + transcript.slice(1);

      const searchInput = document.querySelector('header input[type="search"]')
      searchInput.parentNode.dataset[searchInput.tagName.toLowerCase() + 'value'] = searchInput.value
    }

    recognition.start()
  }
}


// Validation du formulaire d'application de carrière
export function formCarreer() {
  validationEmail()
  formatPhone('#form-carreer-phone')

  var m = getMessages()
  $.validator.addMethod('filesize', function (value, element, param) {
    return this.optional(element) || (element.files[0].size <= param)
  }, 'La taille maximale du fichier doit être 5MB')

  var rules = {
    firstname:     { required: true },
    lastname:      { required: true },
    email:         { required: true, courriel: true },
    phone:         { minlength: 12 },
    cv:            { accept: 'pdf', filesize: 5242880, required: true },
    motivation:    { accept: 'pdf', filesize: 5242880 },
  }
  var messages = {
    firstname:     { required: m.required },
    lastname:      { required: m.required },
    email:         { required: m.required, courriel: m.email },
    phone:         { minlength: m.phone },
    cv:            { accept: m.accept, required: m.required },
    motivation:    { accept: m.accept },
  }

  formValidation('#form-carreer', '#form-carreer-submit', rules, messages)
}


// Validation du formulaire de contact'
export function formContact() {
  validationEmail()
  formatPhone('#form-contact-phone')

  var m = getMessages()

  var rules = {
    firstname:     { required: true },
    lastname:      { required: true },
    email:         { required: true, courriel: true },
    phone:         { minlength: 12 },
    message:       { required: true }
  }
  var messages = {
    firstname:     { required: m.required },
    lastname:      { required: m.required },
    email:         { required: m.required, courriel: m.email },
    phone:         { minlength: m.phone },
    message:       { required: m.required }
  }

  formValidation('#form-contact', '#form-contact-submit', rules, messages)
}


// Validation de la recherche
export function formSearchByAddress(form, submit) {
  var m = getMessages()

  var rules = {
    'civic-number':   { required: true },
    street:           { required: true },
  }
  var messages = {
    'civic-number':   { required: m.required },
    street:           { required: m.required },
  }

  formValidation(form, submit, rules, messages)
}
