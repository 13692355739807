/*

Cette classe gère les markeurs, on utilise les prototypes à cause d’incompatibilités avec Google Map

*/
import $ from 'jquery'
import {mapUrl} from './mapUrl'
import {mapOverlay} from './mapOverlay'
import {mapFilter} from './mapFilter'


// definir une variable pour recevoir la future définition de markeur
export let HTMLMarker

// initMarker est appelé lorsque googlemap est chargé
export function initMarker() {

  // lorsque GoogleMap est chargé on declare un objet et ses prototypes
  HTMLMarker = function (map, lat, lng, id, slug, icon, kmlUrl, categoriesID, accessibility, nom) {

    this.lat = lat
    this.lng = lng
    this.pos = new google.maps.LatLng(lat, lng)
    this.id = id
    this.icon = icon
    this.defaultIcon = icon
    this.accessibility = accessibility
        
    this.kmlUrl = kmlUrl
    this.kmlLayer = null              // contient l’objet KML
    this.kmlBoundsNE = null           // contient les coordonnés du rectangle contenant le KML ( viewport ) attention, peuplé de manière asynchrone !
    this.kmlBoundsSW = null

    this.nom = nom
    this.slug = slug
    this.categoriesID = categoriesID  // tableau des ID de categorie
    HTMLMarker.map = map   
    HTMLMarker.markerList.push(this)  // Ajout du marqueur à la liste qui est une variable static
    this.visibleOnMap = false         // Le Marker est-il visible sur la MAP ?
    this.activeOnLoad = false         // est-ce que ce POI doit être activé au chargement ?

    // si le le slug Localisation, ou Subcategory, ou Category correspond au slug du POI courrant on affiche
    if (mapUrl.getOnLoadSlugLocalisation() == slug) {
      //mapUrl.setSlugLocalisation(slug)
      this.activeOnLoad = true
    }
    else if (mapUrl.getOnLoadSlugSubCategory() == slug) {
      
      mapUrl.setSlugSubCategory(null)
      mapUrl.setSlugLocalisation(slug)
      this.activeOnLoad = true
    }
    else if (mapUrl.getOnLoadSlugCategory() == slug) {
      mapUrl.setSlugCategory(null)
      mapUrl.setSlugLocalisation(slug)
      this.activeOnLoad = true
    }

    // SI UNE URL DE FICHIER KML EST RENSEIGNÉE
    if (this.kmlUrl != null) {
      
      this.kmlLayer = new google.maps.KmlLayer({
        url: this.kmlUrl,
        suppressInfoWindows: true,
        preserveViewport: true, // pour garder le bon viewport
        //map: map
      })

      // les KML sont chargé de manière asynchrone
      // il faut donc utiliser des événements pour récupérer et sauvegarder le viewport
      let curentMarker = this
      
      google.maps.event.addListener(this.kmlLayer, 'defaultviewport_changed', function() {

        // On premier affichage, on enregistre les bounds.
        if ( curentMarker.kmlBoundsNE == null || curentMarker.kmlBoundsSW == null )
        {
          curentMarker.kmlBoundsNE = this.getDefaultViewport().getNorthEast()
          curentMarker.kmlBoundsSW = this.getDefaultViewport().getSouthWest()
          HTMLMarker.fitBounds() // ajuster le zoom          
        }

        // si le markeur est activé au chargement on gère le KML
        if ( curentMarker.activeOnLoad ) {
          curentMarker.activate()
          //mapUrl.setSlugLocalisation(curentMarker.slug)
          curentMarker.activeOnLoad = false
        }        
      })

      // si on clique sur le KML
      this.kmlLayer.addListener('click', function(event) {
        
        // HTMLMarker.activate(curentMarker.id,curentMarker.slug)
        
        // Ici on utiliserais la méthode ci-dessus.
        // Hors, la cliente veut pas de fitBounds lorsqu'on clique sur le KML.
        // Je fait donc un duplicata - sans fitbound.
        
        // Ici on veut activer un Marker sans PIN, il s'agit donc d'un KML.
        if ( mapUrl.getSlugLocalisation() == slug) {

          // Toogle click sur le kml.
          HTMLMarker.desactiveAll()
          mapOverlay.hideSidePanel()
          mapFilter.openCategorieByUrl()
          // HTMLMarker.fitBounds()
        }
        else
        {
          // On active le kml
          HTMLMarker.desactiveAll()
          HTMLMarker.activeEvent(id) // envoi l’evenement pour afficher le panneau
          mapUrl.setSlugLocalisation(slug)

          // envoi un evenement de click generique
          let customEvent = new CustomEvent('marker.activated', {'detail': { 'id':id, 'slug':slug}})
          window.dispatchEvent(customEvent)

          // On rafraichis l'affichage.
          // HTMLMarker.showUniqueMarker(id) -- Fit bound ici aussi
          
          mapOverlay.hideSidePanel()
          HTMLMarker.markerList.map(function(marker) {
            if (marker.id == id) {
              marker.show()
            } else {
              marker.hide()
            }
          })
        }        
      })
    }
  
    if (this.lat != null && this.lng != null) {
      let div = document.createElement('DIV')
      div.style.position = 'absolute'
      div.className = 'htmlMarker'
      div.style.display = 'none'
      div.innerHTML = '' + this.renderMarker()
      this.div = div
    }

    this.setMap(map)
  }

  HTMLMarker.prototype = new google.maps.OverlayView()

  // affiche le markeur courant
  HTMLMarker.prototype.show = function() {

    this.visibleOnMap = true
    
    if ( this.kmlLayer != null ) {

      if (mapUrl.isOnRoot() && !this.activeOnLoad)
      {
        this.kmlLayer.setMap(null)
      }
      else
      {
        this.kmlLayer.setMap(HTMLMarker.map)
      }
    }

    if (this.div)
    {
      // On redessine donc le marker pour le changement d'icone.
      this.div.innerHTML = this.renderMarker()
      $(this.div).fadeIn(150)
    }
  }

  // cache le markeur courant
  HTMLMarker.prototype.hide = function() {

    this.visibleOnMap = false
    if (this.kmlLayer != null) this.kmlLayer.setMap(null)
    $(this.div).fadeOut(150)
  }

  //init your html element here
  HTMLMarker.prototype.onAdd = function () {

    // si pas de latitude et de longitude, c’est uniquement un KML, on skip l’affichage
    if (this.lat == null || this.lng == null) return
    
    var panes = this.getPanes()
    panes.overlayImage.appendChild(this.div)

    // si ce POI est activé au chargement
    if (this.activeOnLoad) {
      $('a', this.div).trigger('click')
      this.activeOnLoad = false
    }
  }

  HTMLMarker.prototype.renderMarker = function () {
    return '<a href="#' + this.slug + '-' + this.id + '" id="' + this.slug + '-' + this.id + '" class="mapPin ' + this.accessibility + '" data-slug="' + this.slug + '"title="' + this.nom + '"' +
      '" onClick="HTMLMarker.activate(' + this.id + ', \'' + this.slug + '\'); return false;">' +
      '<svg class="marker" width="30" height="30" role="img">' +
      '<use xlink:href="/plugins/blanko/carte/assets/images/icons/map.svg#ico-puce2"></use>' +
      '</svg>' +
      '<svg class="icon" width="30" height="30" role="img">' +
      '<use xlink:href="/plugins/blanko/carte/assets/images/icons/icons.svg#ico-' + this.icon + '"></use>' +
      '</svg>' +
      '</a>'
  }

  HTMLMarker.prototype.draw = function () {

    if (this.lat == null || this.lng == null) return

    var overlayProjection = this.getProjection()
    var position = overlayProjection.fromLatLngToDivPixel(this.pos)
    this.div.style.left = position.x + 'px'
    this.div.style.top = position.y - 30 + 'px'
  }

  // Methode appelée automatiquement si on setMap(null) l’objet, voir doc Google
  HTMLMarker.prototype.onRemove = function () {

    if (this.lat == null || this.lng == null) return

    $(this.div).fadeOut(150, () => {
      // si actif, fermer le panel avant le desaffichage
      if ($('a', this.div).hasClass('active')) {
        mapOverlay.hideSidePanel()
      }
      this.div.parentNode.removeChild(this.div)
      this.div = null
    })
  }

  // active le POI courant
  HTMLMarker.prototype.activate = function() {

    // envoi un evenement de demande d’ouverture écouté par le panneau
    HTMLMarker.activeEvent(this.id)

    // envoi un evenement de click generique pour le control du submenu
    let event = new CustomEvent('marker.activated', {'detail': { 'id':this.id, 'slug':this.slug }})
    window.dispatchEvent(event)

    mapUrl.setSlugLocalisation(this.slug)
  }

  /********************************
    PROPRIÉTÉS ET METHODES STATICS
   *******************************/
  HTMLMarker.map = null
  HTMLMarker.markerList = []
  HTMLMarker.minZoom = 15 // zomm minimum de la carte

  // active visuellement un marqueur
  HTMLMarker.activate = function (id,slug) {
    
    let selectorPOI = '#'+slug+'-'+id

    if ($(selectorPOI).length)
    {
      if ( !$(selectorPOI).hasClass('active') )
      {
        // POI non actif, donc on l'active
        HTMLMarker.desactiveAll()
        $(selectorPOI).parent().css('z-index',+1)
        $(selectorPOI).addClass('active')

        // envoi un evenement de click generique pour le control du submenu
        let event = new CustomEvent('marker.activated', {'detail': { 'id':id, 'slug':slug }})
        window.dispatchEvent(event)

        // envoi un evenement de demande d’ouverture écouté par le panneau
        HTMLMarker.activeEvent(id)

        // met à jour l’URL
        mapUrl.setSlugLocalisation(slug)
        
        let marker = HTMLMarker.getBySlug(slug)
        if (!HTMLMarker.map.getBounds().contains(marker.pos))
        {
          HTMLMarker.fitBounds()
        }
      }
      else
      {
        // ici on clique un POI actif, donc on le ferme.
        HTMLMarker.desactiveAll()
        $(selectorPOI).removeClass('active')
        //UI.overlayMapDetail.close()
        mapOverlay.hideSidePanel()
      }
    }
    else
    {
      // Ici on veut activer un Marker sans PIN, il s'agit donc d'un KML.
      if ( mapUrl.getSlugLocalisation() == slug) {

        // Toogle click sur le kml.
        HTMLMarker.desactiveAll()
        mapOverlay.hideSidePanel()
        mapFilter.openCategorieByUrl()        
        HTMLMarker.fitBounds()
      }
      else
      {
        // On active le kml
        HTMLMarker.desactiveAll()
        HTMLMarker.activeEvent(id) // envoi l’evenement pour afficher le panneau
        mapUrl.setSlugLocalisation(slug)

        // envoi un evenement de click generique
        let customEvent = new CustomEvent('marker.activated', {'detail': { 'id':id, 'slug':slug}})
        window.dispatchEvent(customEvent)

        // On rafraichis l'affichage.
        HTMLMarker.showUniqueMarker(id)
      }
    }
  }

  HTMLMarker.getBySlug = function(slug) {

    for (var i=0; i < HTMLMarker.markerList.length; i++) {
      if (HTMLMarker.markerList[i].slug === slug) {
        return HTMLMarker.markerList[i]
      }
    }
  }

  HTMLMarker.activeEvent = function( id ) {

    // envoi un evenement de demande d’ouverture écouté par le panneau
    let event = new CustomEvent('marker.panel.show', {'detail': { 'id':id }})
    window.dispatchEvent(event)
  }

  HTMLMarker.desactiveAll = function() {

    $('.mapPin.active').removeClass('active')
    $('.htmlMarker').css('z-index', -1)
    //$('.drawersLVL2Content li.active').removeClass('active')
    $('.subLvl2 li.active').removeClass('active')
    mapUrl.setSlugLocalisation(null)
  }

  // n’affiche plus les marqueurs sur la map
  HTMLMarker.hideAll = function() {
    HTMLMarker.markerList.map(function(marker) {
      marker.hide()
    })
  }

  // affiche les marqueurs sur la map
  HTMLMarker.showAll = function() {

    //UI.overlayMapDetail.close()
    mapOverlay.hideSidePanel()
    HTMLMarker.markerList.map(function(marker) {
      marker.icon = marker.defaultIcon
      marker.show()
    })
  }

  HTMLMarker.showUniqueMarker = function(markerId) {

    //UI.overlayMapDetail.close()
    mapOverlay.hideSidePanel()

    HTMLMarker.markerList.map(function(marker) {

      if (marker.id == markerId) {
        marker.show()
      } else {
        marker.hide()
      }
    })

    HTMLMarker.fitBounds()
  }

  HTMLMarker.showCategory = function(categoryID) {

    //UI.overlayMapDetail.close()
    mapOverlay.hideSidePanel()

    let catIcone = null
    window.poisCategories.map(function(cat) {
      if (cat['id'] == categoryID) {
        catIcone = cat['icone']
      }
    })

    HTMLMarker.markerList.map(function(marker) {

      if (marker.categoriesID.includes(categoryID)) {

        marker.icon = catIcone
        marker.show()

      } else {

        marker.hide()
      }
    })
  }

  // Adapte la map pour faire fiter tout les markers dans la vue
  HTMLMarker.fitBounds = function() {

    let bounds = new google.maps.LatLngBounds()

    HTMLMarker.markerList.map(function(marker) {
      // pour chaque marker activé on étend le rectangle de visualisation si le POI dispose de coordonnés ( sinon ça signifie que c’est un KML )
      if ( marker.visibleOnMap && marker.lat != null && marker.lng != null )  { bounds.extend(marker.pos) }
      if ( marker.visibleOnMap && marker.kmlLayer != null ) {
        if ( marker.kmlLayer.getMap() ) {
          if (marker.kmlBoundsNE != null) bounds.extend( marker.kmlBoundsNE )
          if (marker.kmlBoundsSW != null) bounds.extend( marker.kmlBoundsSW )
        }
      }
    })

    HTMLMarker.map.fitBounds(bounds)
    let zoom = HTMLMarker.map.getZoom()
    if (zoom > HTMLMarker.minZoom) zoom = HTMLMarker.minZoom
    HTMLMarker.map.setZoom(zoom)
  }

  window.HTMLMarker = HTMLMarker
}