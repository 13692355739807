// |--------------------------------------------------------------------------
// | Fonctions
// |--------------------------------------------------------------------------
// |
// | Les divers scripts du site.
// |--------------------------------------------------------------------------

import LazyLoad from 'vanilla-lazyload'
import { OBSERVER, SCROLLFIRE } from './../main.js'
import anime from 'animejs/lib/anime.es.js'
import { getElementOffset, getCookie, setCookie, isMobile } from './helper.js'
import { assistantSlider, slickAssistant } from './sliders.js'
import { Accordions } from './accordions.js'
import Chart from 'chart.js/auto';
import 'chartjs-adapter-moment';
import moment from "moment";
import "moment/locale/fr";


// Fonction ajoutant l'événement 'click' qui appellera la fonction 'scrollToBlock'
export const clickToScrollToBlock = (options) => {
  options.duration === undefined ? options.duration = 800              : options.duration
  options.scrollTo === undefined ? options.scrollTo = 'html, body'     : options.scrollTo
  options.easing   === undefined ? options.easing   = 'easeInOutQuart' : options.easing
  options.offset   === undefined ? options.offset   = 0 : options.offset

  const onClick = () => scrollToBlock({
    scrollTo: options.scrollTo,
    duration: options.duration,
    easing: options.easing,
    offset: options.offset,
  })

  OBSERVER.add({
    name: 'scrollToBlock',
    event: 'click',
    target: options.selector,
    function: onClick
  })

  OBSERVER.on('scrollToBlock')
}


// Fonction exécutant l'animation du scroll vers son bloc
export const scrollToBlock = (options = {}) => {
  options.duration === undefined ? options.duration = 800              : options.duration
  options.scrollTo === undefined ? options.scrollTo = 'html, body'     : options.scrollTo
  options.easing   === undefined ? options.easing   = 'easeInOutQuart' : options.easing
  options.offset   === undefined ? options.offset   = 0 : options.offset

  const scrollbar = window.document.scrollingElement || window.document.body || window.document.documentElement
  const element = typeof unknownElement === 'string' ? document.querySelector(options.scrollTo) : options.scrollTo

  const animation = anime.timeline({
    targets: scrollbar,
    duration: options.duration,
    easing: options.easing
  }).add({ scrollTop: getElementOffset({ element: element }).top + options.offset })

  return animation.finished
}

// Ajouter des marges pour les <p> qui contiennent des boutons
export function adjustButtonsMargins(){
  $('.dynamic p > a.button, .dynamic p > a.button--secondary').each(function() {
    $(this).parent().addClass('buttonMargin')
  })
  $('.dynamic p.buttonMargin').each(function() {
    if(!($(this).prev().hasClass('buttonMargin'))){
      $(this).addClass('buttonMargin--first')
    }
    if(!($(this).next().hasClass('buttonMargin'))){
      $(this).addClass('buttonMargin--last')
    }
  })
}

// Englobe les iframe de vidéos youtube pour gérer le ratio
export function wrapYtVideo() {
  let i,
    youtubeVideosOuterHtml,
    newYoutubeVideosOuterHtml,
    youtubeVideos = document.querySelectorAll('.dynamic iframe[src*="youtube.com"],.dynamic iframe[src*="vimeo.com"]'),
    youtubeVideosLength = youtubeVideos.length

  for(i=0; i<youtubeVideosLength; i++) {
    youtubeVideosOuterHtml = youtubeVideos[i].outerHTML
    newYoutubeVideosOuterHtml = `<div class='videoWrapper'><div class='ytVideo'>${youtubeVideosOuterHtml}</div></div>`
    youtubeVideos[i].outerHTML = newYoutubeVideosOuterHtml
  }
}

// Ajouter un <span> dans les boutons pour l'animation
export function addSpanToButtons() {
  let buttons = $('.button, .button--secondary, .js-hover-btn')
  buttons.wrapInner('<span></span>')
}

// Ajouter les icones svg pour les boutons et les liens textes
export function addSvgToLinks() {

  let linksExternal = document.querySelectorAll('.dynamic a[target=_blank]:not([href$=".pdf"]):not([href$=".doc"]):not([href$=".zip"]), .externalIcon[target=_blank]:not([href$=".pdf"]):not([href$=".doc"]):not([href$=".zip"])')
  linksExternal.forEach(function(link) {
    if (!link.querySelector('img')) {
      var svgElem = document.createElementNS('http://www.w3.org/2000/svg', 'svg'),
        useElem = document.createElementNS('http://www.w3.org/2000/svg', 'use')
      useElem.setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', '/themes/init-theme/assets/medias/images/icons/symbols.svg#ico-external')
      svgElem.appendChild(useElem)

      if(!(link.classList.contains('button--primary') || link.classList.contains('button--secondary') || link.classList.contains('button') || link.classList.contains('externalIcon') || link.classList.contains('btn--map'))){
        // Prendre la valeur du dernier mot dans une variable
        let splitString = link.textContent.split(' ')
        let lastWord = splitString[splitString.length - 1]

        // Retirer le dernier mot du texte du lien
        var lastIndex = link.textContent.lastIndexOf(' ')
        link.textContent = link.textContent.substring(0, lastIndex)
        link.textContent = link.textContent+ ' '

        //console.log(lastWord)

        // Créer une node span et mettre le dernier mot et le svg à l'intérieur
        let spanNode = document.createElement('span')
        spanNode.classList.add('svgNonBreak')
        spanNode.textContent = lastWord
        spanNode.appendChild(svgElem)

        link.appendChild(spanNode)
      } else {
        svgElem.appendChild(useElem)
        link.appendChild(svgElem)
      }
    }
  })

  let linksDocuments = document.querySelectorAll('.dynamic a[href$=".pdf"], .dynamic a[href$=".doc"], .dynamic a[href$=".zip"]')
  linksDocuments.forEach(function(link) {
    if (!link.querySelector('img')) {
      var svgElem = document.createElementNS('http://www.w3.org/2000/svg', 'svg'),
        useElem = document.createElementNS('http://www.w3.org/2000/svg', 'use')
      useElem.setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', '/themes/init-theme/assets/medias/images/icons/symbols.svg#ico-download')
      svgElem.appendChild(useElem)

      if(!(link.classList.contains('button--primary') || link.classList.contains('button--secondary') || link.classList.contains('button'))){

        // Prendre la valeur du dernier mot dans une variable
        let splitString = link.textContent.split(' ')
        let lastWord = splitString[splitString.length - 1]

        // Retirer le dernier mot du texte du lien
        var lastIndex = link.textContent.lastIndexOf(' ')
        link.textContent = link.textContent.substring(0, lastIndex)
        link.textContent = link.textContent+ ' '

        // Créer une node span et mettre le dernier mot et le svg à l'intérieur
        let spanNode = document.createElement('span')
        spanNode.classList.add('svgNonBreak')
        spanNode.textContent = lastWord
        spanNode.appendChild(svgElem)

        link.appendChild(spanNode)
      } else {
        svgElem.appendChild(useElem)
        link.appendChild(svgElem)
      }
    }
  })

}

// Fonction permettant d'activer le calendrier
export const calendar = (home = false, root = document) => {
  if (!root.querySelector('#calendarOptions')) //s'il n'y a pas d'events
    return

  let eventsDatesList = root.querySelector('#calendarOptions').dataset.list
  let currentDate = root.querySelector('#calendarOptions').dataset.date

  $.fn.datepicker.dates['fr'] = {
    days: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
    daysShort: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
    daysMin: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
    months: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
    monthsShort: ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Juin', 'Jui', 'Août', 'Sep', 'Oct', 'Nov', 'Déc'],
    today: 'Aujourd\'hui',
    clear: 'Clear',
    format: 'dd/mm/yyyy',
    titleFormat: 'MM yyyy',
    weekStart: 0
  }

  let datesEnabled = eventsDatesList.split(',')

  $(root).find('.calendar').datepicker({
    language: 'fr',
    maxViewMode: 0,
    format: 'yyyy-mm-dd',
    todayHighlight: true,
    beforeShowDay: function (date) { // Rendre seulement les dates de la liste d'événements disponibles
      let allDates = date.getDate() + '-' + (date.getMonth() + 1) + '-' + date.getFullYear()
      if(datesEnabled.indexOf(allDates) != -1) return true; else return false
    }
  }).on('changeDate', function(e) {

    let theTimestamp =  Date.parse(e.date)/1000 //Le timestamp du date picker est en millisecondes, il faut le mettre en secondes

    if (home) {

      $('.featured-events__slider').css('max-height', $('.featured-events__slider').height())
      $('.featured-events__slider').css('opacity', 0)
      $('.js-events-slider').slick('unslick');

      $.request('onChangeDate', {
        data: {dateFilter: theTimestamp},
        update: {'EvenementsVedette::list':'#eventsList', 'EvenementsVedette::label':'#calendarLabel'},
        complete: function (data) { data.then(function(){
          slickEvents();
          lazyloadImages();

          calendarOverlay.close()
          $('.featured-events__slider').css('opacity', 1)
          $('.featured-events__slider').css('max-height', 'none')
        })},
      })
    }
    else {
      $.request('onChangeDate', {
        data: {dateFilter: theTimestamp},
        complete: function (data) { data.then(function(data){ window.location.replace('/evenements/1/' + data['date'])})},
      })
    }
    //Fermer l'overlay quand on clique
    $('#overlayCalendar .wrapper a.close span.x').trigger('click')
  })

  $(root).find('.calendar').datepicker('update', currentDate)
}


export function newsImages() {

  OBSERVER.add({
    name: 'displayImages',
    event: 'mouseenter',
    target: '.js-news-image-trigger',
    function: mouseenter
  })
  OBSERVER.on('displayImages')

  OBSERVER.add({
    name: 'displayImages',
    event: 'mouseleave',
    target: '.js-news-image-trigger',
    function: mouseleave
  })
  OBSERVER.on('displayImages')

  function mouseenter(e) {
    if(typeof e.target.dataset.pictureid !== 'undefined'){
      let pictureId = e.target.dataset.pictureid
      document.querySelector('.js-news-image-target[data-pictureid="'+pictureId+'"]').classList.add('featured-news__image--active')
    }
  }

  function mouseleave(e) {
    if(typeof e.target.dataset.pictureid !== 'undefined'){
      let pictureId = e.target.dataset.pictureid
      document.querySelector('.js-news-image-target[data-pictureid="'+pictureId+'"]').classList.remove('featured-news__image--active')
    }
  }

}

export function copyTextToClipboard(text) {
  // if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text)
    return
  // }
  // navigator.clipboard.writeText(text).then(function() {
  //   console.log('Async: Copying to clipboard was successful!')
  // }, function(err) {
  //   console.error('Async: Could not copy text: ', err)
  // })
}

function fallbackCopyTextToClipboard(text) {
  let pos = $(document).scrollTop()

  let textArea = document.createElement('textarea')
  textArea.value = text
  document.body.appendChild(textArea)
  textArea.focus()
  textArea.select()

  try {
    document.execCommand('copy')
  } catch (err) { console.log(err)}

  document.body.removeChild(textArea)
  $(document).scrollTop(pos)
}


export function manageNavTrack(){


  if( document.readyState !== 'loading' ) {
      setNavTrackHeight()
  } else {
      document.addEventListener('DOMContentLoaded', function () {
          setNavTrackHeight()
      });
  }

  function setNavTrackHeight(){
    let navTrack = document.querySelector('.js-nav-track')

    // Première compososante qui dépasse sur la sidebar
    let offsetComponent = document.querySelector('.main__component--offset')

    let sidebarTargetHeight = offsetComponent.offsetTop - document.querySelector('.main__sidebar').offsetTop
    let sidebarHeight = document.querySelector('.main__sidebar').offsetHeight
    let padding = sidebarHeight - sidebarTargetHeight
    navTrack.style.paddingBottom = padding + 'px'

  }

  OBSERVER.add({
    name: 'resizeTrackHeight',
    event: 'resize',
    target: 'window',
    function: setNavTrackHeight
  })
  OBSERVER.on('resizeTrackHeight')

}


// Fonction gérant les alertes
export function alerts() {
  const closeAlertsBanner = e => anime({ targets: document.querySelector('.js-alerts-banner'), height: '0px', easing: 'easeOutExpo', duration: 400 })
  OBSERVER.add({ name: 'alerts', event: 'click', function: closeAlertsBanner, target: '.js-alerts-close' })
  OBSERVER.on('alerts')
}


// Fonction qui gère le label du searchInput
export function initSearchInput() {
  $('#form-search-input').focus( function(){
		$('#form-search-label').hide();
	})
	$('#form-search-input').blur( function(){
		if ($(this).val() != '')
			$('#form-search-label').hide();
		else
			$('#form-search-label').show();
	})
}


// Initialise le zoom sur la typo
export function initZoom() {
  let cookie = getCookie('zoom')
  let i, bindSetZoom
  let html = document.getElementsByTagName('html')[0]
  let accessibility = document.querySelectorAll('.js-text-size')
  let accessibilityLength = document.querySelectorAll('.js-text-size').length

  // Initialisation par défaut de la font-size et de son animation
  if (cookie == '') {
    setCookie('zoom', '62.5', 7)
    html.style.fontSize = '62.5%'
  } else {
    html.style.fontSize = getCookie('zoom') + '%'
  }
  setTimeout(() => { html.style.transition = 'font-size 300ms' }, 300)

  // Création des événements de clique
  for (i=0; i<accessibilityLength; i++) {
    if (accessibility[i].dataset.zoom + '%' == html.style.fontSize)
      accessibility[i].classList.add('active')
    bindSetZoom = e => setZoom(e)
    accessibility[i].addEventListener('click', bindSetZoom, false)
  }

  // Événement de clique pour ajouter la bonne font-size
  function setZoom(e) {
    setCookie('zoom', e.target.dataset.zoom, 7)
    for (i=0; i<accessibilityLength; i++)
      accessibility[i].classList.remove('active')
    e.target.classList.add('active')
    html.style.fontSize = e.target.dataset.zoom + '%'
  }
}

export function changeSelect(select) {
  // window.addEventListener('load', function () {
    $('.dropdown-option').on('click', function() {
      $(select).trigger('change')
    });
  // })

}

export function activeFilter(element) {
  $('.pills__item').removeClass('pills__item--active')
  $(element).parent().addClass('pills__item--active')
}

export function newsPushCateg(data) {

  if (data.categorie)
  {
    history.pushState({}, '', '/actualites/' + data.categorie.slug_seo_fr)
  }
  else
  {
    history.pushState({}, '', '/actualites')
  }
}

export function newsLoaded(data) {
  if (!data['hasMorePages'])
    $('#newsMoreBtn').hide()
  else {
    $('#activitiesMoreBtn').show()
  }
}

//Permet d'enlever les accents d'une string
export function slugifyProvider(provider) {
  let result
  result = provider.replace('é', 'e')
  result = result.replace('É', 'E')
  result = result.replace(/ /g,"-")
  result = result.toLowerCase()

  return result
}


// Fonction permettant de créer un navigation horizontale en slidant
export function dragAndSlide(options = {}) {
  options.nameClass === undefined ? options.nameClass = 'drag-and-slide' : options.nameClass
  options.containerClass === undefined ? options.containerClass = 'js-drag-and-slide' : options.containerClass
  options.contentClass === undefined ? options.contentClass = 'js-drag-and-slide-content' : options.contentClass
  options.prevClass === undefined ? options.prevClass = 'js-drag-and-slide-prev' : options.prevClass
  options.nextClass === undefined ? options.nextClass = 'js-drag-and-slide-next' : options.nextClass

  const doc = document,
    container = doc.querySelector(`.${options.containerClass}`),
    content = doc.querySelector(`.${options.contentClass}`),
    prev = doc.querySelector(`.${options.prevClass}`),
    next = doc.querySelector(`.${options.nextClass}`),
    links = doc.querySelectorAll(`.${options.contentClass} a`),
    linksLength = links.length

  let i,
    startX,
    timeoutPreventDefault,
    scrollLeft,
    navigationIsDown = false,
    direction = 'right',
    isDown = false,
    preventDefault = false

  // Empêcher de drag les liens
  for (i = 0; i < linksLength; i++)
    links[i].ondragstart = function() { return false }

  function mouseDown(e) {
    isDown = true
    container.classList.add('active')
    startX = e.pageX - container.offsetLeft
    scrollLeft = container.scrollLeft
    timeoutPreventDefault = setTimeout(() => {
      preventDefault = true
    }, 300)
  }

  function mouseleave() {
    isDown = false
    container.classList.remove('active')
  }

  function mouseup() {
    clearTimeout(timeoutPreventDefault)
    isDown = false
    container.classList.remove('active')
  }

  function mousemove(e) {
    if(!isDown) return
    e.preventDefault()
    const x = e.pageX - container.offsetLeft
    const walk = (x - startX) * 2
    container.scrollLeft = scrollLeft - walk
  }

  function click(e) {
    if(preventDefault) {
      e.preventDefault()
      e.stopPropagation()
    }
    preventDefault = false
  }

  function onResize() {
    const containerWidth = container.clientWidth
    const contentWidth = content.clientWidth
    const prevClass = `${options.name}__prev--is-active`
    const nextClass = `${options.name}__next--is-active`

    if(contentWidth > containerWidth) {
      prev.classList.add(prevClass)
      next.classList.add(nextClass)
    } else {
      prev.classList.remove(prevClass)
      next.classList.remove(nextClass)
    }
    onScroll()
  }

  function onScroll() {
    const maxScrollLeft = container.scrollWidth - container.clientWidth
    const prevClass = `${options.name}__prev--is-visible`
    const nextClass = `${options.name}__next--is-visible`

    if(container.scrollLeft > 0) {
      prev.classList.add(prevClass)
    } else {
      prev.classList.remove(prevClass)
    }
    if(container.scrollLeft < maxScrollLeft) {
      next.classList.add(nextClass)
    } else {
      next.classList.remove(nextClass)
    }
  }

  onResize()
  onScroll()

  function setPosition(newPosition) {
    container.scrollLeft = container.scrollLeft + newPosition
  }

  function horizontaleScroll2() {
    if (navigationIsDown) {
      container.style.scrollBehavior = 'smooth'
      const containerWidth = container.clientWidth

      if (direction === 'right')
        setPosition(containerWidth /2)
      else
        setPosition(containerWidth / (-2))

      setTimeout(() => {
        requestAnimationFrame(horizontaleScroll2)
      })
    }
  }

  function mouseDownNext(e) {
    e.preventDefault()
    navigationIsDown = true
    direction = 'right'
    horizontaleScroll2()
    return false
  }

  function mouseDownPrev(e) {
    e.preventDefault()
    navigationIsDown = true
    direction = 'left'
    horizontaleScroll2()
    return false
  }

  function navigationIsDownFalse() {
    navigationIsDown = false
    return false
  }

  OBSERVER.add({ name: 'dragAndSlide', event: 'resize', function: onResize })
  OBSERVER.add({ name: 'dragAndSlide', event: 'scroll', function: onScroll, target: container })
  OBSERVER.add({ name: 'dragAndSlide', event: 'mousedown', function: mouseDown, target: container })
  OBSERVER.add({ name: 'dragAndSlide', event: 'mouseleave', function: mouseleave, target: container })
  OBSERVER.add({ name: 'dragAndSlide', event: 'mouseup', function: mouseup, target: container })
  OBSERVER.add({ name: 'dragAndSlide', event: 'mousemove', function: mousemove, target: container })
  OBSERVER.add({ name: 'dragAndSlide', event: 'click', function: click, target: `.${options.contentClass} a` })
  OBSERVER.add({ name: 'dragAndSlide', event: 'click', function: click, target: `.${options.contentClass} a` })

  OBSERVER.add({ name: 'dragAndSlide', event: 'mousedown', function: mouseDownNext, target: next })
  OBSERVER.add({ name: 'dragAndSlide', event: 'mouseleave', function: navigationIsDownFalse, target: next })
  OBSERVER.add({ name: 'dragAndSlide', event: 'mouseup', function: navigationIsDownFalse, target: next })
  OBSERVER.add({ name: 'dragAndSlide', event: 'touchstart', function: mouseDownNext, target: next })
  OBSERVER.add({ name: 'dragAndSlide', event: 'touchend', function: navigationIsDownFalse, target: next })
  OBSERVER.add({ name: 'dragAndSlide', event: 'touchcancel', function: navigationIsDownFalse, target: next })
  OBSERVER.add({ name: 'dragAndSlide', event: 'touchleave', function: navigationIsDownFalse, target: next })

  OBSERVER.add({ name: 'dragAndSlide', event: 'mousedown', function: mouseDownPrev, target: prev })
  OBSERVER.add({ name: 'dragAndSlide', event: 'mouseleave', function: navigationIsDownFalse, target: prev })
  OBSERVER.add({ name: 'dragAndSlide', event: 'mouseup', function: navigationIsDownFalse, target: prev })
  OBSERVER.add({ name: 'dragAndSlide', event: 'touchstart', function: mouseDownPrev, target: prev })
  OBSERVER.add({ name: 'dragAndSlide', event: 'touchend', function: navigationIsDownFalse, target: prev })
  OBSERVER.add({ name: 'dragAndSlide', event: 'touchcancel', function: navigationIsDownFalse, target: prev })
  OBSERVER.add({ name: 'dragAndSlide', event: 'touchleave', function: navigationIsDownFalse, target: prev })
  OBSERVER.on('dragAndSlide')
}


// Retire tous les effets d'over sur le mobile
export function removeHoverOnMobile() {
  if (/Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    try { // prevent exception on browsers not supporting DOM styleSheets properly
      for (var si in document.styleSheets) {
        var styleSheet = document.styleSheets[si]
        if (!styleSheet.rules) continue

        for (var ri = styleSheet.rules.length - 1; ri >= 0; ri--) {
          if (!styleSheet.rules[ri].selectorText) continue
          if (styleSheet.rules[ri].selectorText.match(':hover')){
            var selectorText = styleSheet.rules[ri].selectorText
            var araySelectorText = selectorText.split(',')
            var newSelectorText = ''

            // Garder slm les selectorText no :hover
            for (var i = 0; i < araySelectorText.length; i++){
              if (!araySelectorText[i].match(':hover')){
                newSelectorText += (newSelectorText !== '')? ', ' + araySelectorText[i] : ' ' + araySelectorText[i]
              }
            }

            // Enlver la règle si la seule classe est un :hover
            if (newSelectorText === ''){ styleSheet.deleteRule(ri) }
            else { styleSheet.rules[ri].selectorText = newSelectorText }
          }
        }
      }
    } catch (error) { console.log(error) }
  }
}


// Fonction permettant de changer la variable css --vh
export function changeVariableVhCss(){
  changeValueVariable()

  // Resize page
  window.addEventListener('resize', () => {changeValueVariable()})

  function changeValueVariable() {
    let vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty('--vh', `${vh}px`)
  }
}


export function removeBackgroundFixedSection() {
  if (isMobile()) {
    const featuredEvents = document.querySelector('.js-featured-events')
    const homeMap = document.querySelector('.js-home-map')

    if (featuredEvents) {
      featuredEvents.style.backgroundAttachment = 'initial'
    }

    if (homeMap) {
      homeMap.style.backgroundAttachment = 'initial'
    }
  }
}

// Fonction permettant de changer la variable css --vh
export function manageSliderHeight(){
  changeHeight()

  // Resize page
  window.addEventListener('resize', () => {changeHeight()})

  function changeHeight() {
    if(window.innerWidth <= 768) {
      let sliderHeight = window.innerHeight - document.querySelector('.header').offsetHeight
      document.querySelector('.home-slider').style.height = sliderHeight+'px'
    }else{
      document.querySelector('.home-slider').style.height = 'auto'
    }
  }
}

export function manageCredit(){
  function toggleCredit(e){
    e.target.classList.toggle('credit--mobile-toggle-on')
  }

  OBSERVER.add({
    name: 'toggleCredit',
    event: 'click',
    target: '.js-toggle-credit',
    function: toggleCredit
  })
  OBSERVER.on('toggleCredit')
}

export function refreshLazyLoad(){
  window['lazyload'] = new LazyLoad()
  SCROLLFIRE.init()
}

export function resetAssistantSlider() {
  assistantSlider.slick('unslick')
  slickAssistant()
}

export function resetAssistantAccordions() {
  new Accordions()
}


// Fonction permettant de suggérer des valeurs pour les champs du formulaire de recherche par adresse
export function formSearchByAddressAutocomplete() {
  const targetFieldCivicNumberWrapper   = '#form-search-by-address-wrapper--civic-number'
  const targetFieldStreetWrapper        = '#form-search-by-address-wrapper--street'
  const targetFieldCivicNumber          = '#form-search-by-address-input--civic-number'
  const targetFieldStreet               = '#form-search-by-address-input--street'

  var numberAutocompleteOptions = {
    noCache: true,
    params: { 'civic-number' : '' },
    serviceUrl: '/blanko/addrsearch/autocomplete/civic',
    appendTo: targetFieldCivicNumberWrapper
  }

  $(targetFieldCivicNumber).autocomplete(numberAutocompleteOptions)

  $(targetFieldStreet).autocomplete({
    serviceUrl: '/blanko/addrsearch/autocomplete/street',
    appendTo: targetFieldStreetWrapper,
    onSelect: function (suggestion) {
      numberAutocompleteOptions.params = { 'street' : $(targetFieldStreet).val() }
      $(targetFieldCivicNumber).autocomplete().setOptions(numberAutocompleteOptions)
    }
  })

  $(targetFieldStreet).keyup( function() {
    numberAutocompleteOptions.params = { 'street' : $(targetFieldStreet).val() }
    $(targetFieldCivicNumber).autocomplete().setOptions(numberAutocompleteOptions)
  })
}

export function adresseSearchResultsLoaded() {
  scrollToBlock({
    scrollTo: document.querySelector('#searchResultsWrapper'),
    easing: 'easeInOutQuart',
    duration: 700,
    offset: -50
  });
  new Accordions();
}

export function dynamicPackage() {
  adjustButtonsMargins()
  addSpanToButtons()
  wrapYtVideo()
  addSvgToLinks()
}

export function lazyloadImages() {
  window['lazyload'] = new LazyLoad()
}

export function initButtonsVisibility() {

  OBSERVER.add({
    name: 'manageButtonsVisibility',
    event: 'scroll',
    root: document,
    function: manageButtonsVisibility
  })

  manageButtonsVisibility()
  OBSERVER.on('manageButtonsVisibility')
}

function manageButtonsVisibility(){
  if(elementInViewport(document.querySelector('.footer__breadcrumbs-and-back-to-top'))) {
    document.querySelector('html').classList.add('hide-buttons')
  } else {
    document.querySelector('html').classList.remove('hide-buttons')
  }
}

function elementInViewport(el) {
  var top = el.offsetTop;
  var left = el.offsetLeft;
  var width = el.offsetWidth;
  var height = el.offsetHeight;

  while(el.offsetParent) {
    el = el.offsetParent;
    top += el.offsetTop;
    left += el.offsetLeft;
  }

  return (
    top >= window.pageYOffset &&
    left >= window.pageXOffset &&
    (top + height) <= (window.pageYOffset + window.innerHeight) &&
    (left + width) <= (window.pageXOffset + window.innerWidth)
  );
}

export function manageContactCta() {
  clickToScrollToBlock({ selector: '.js-scroll-to-info', scrollTo: '.js-info', offset: -50 })
  clickToScrollToBlock({ selector: '.js-scroll-to-form', scrollTo: '.js-form', offset: -50 })
}

export function gauge() {
  const gaugesCanvas = document.querySelectorAll('.js-gauge');

  if (gaugesCanvas.length <= 0) { return; }

  const globalOptions = {
    angle: -0.30,
    lineWidth: 0.15,
    pointer: {
      length: 0.5,
      strokeWidth: 0.035,
      iconScale: 1.0,
      color: '#115564'
    },
  }

  gaugesCanvas.forEach((element) => {
    const options = globalOptions;
    options.staticZones = [];

    const currentValue = +element.getAttribute('data-gauge-current-value');
    const minValue = +element.getAttribute('data-gauge-min-value');
    const maxValue = +element.getAttribute('data-gauge-max-value');
    const points = element.getAttribute('data-gauge-points').split(';');
    const colors = element.getAttribute('data-gauge-colors').split(';');

    colors.forEach((color, index) => {
      let staticZone = {};

      if (index === 0) {
        staticZone.min = minValue;
        staticZone.max = +points[index];
      } else if (index === colors.length - 1) {
        staticZone.min = +points[index - 1];
        staticZone.max = maxValue;
      } else {
        staticZone.min = +points[index - 1];
        staticZone.max = +points[index];
      }

      staticZone.strokeStyle = color;

      options.staticZones = [...options.staticZones, staticZone];
    });

    const gauge = new Gauge(element).setOptions(options);

    const current = element.parentNode.querySelector('.js-gauge-current-value');
    gauge.setTextField(current);

    gauge.maxValue = maxValue;
    gauge.setMinValue(minValue);
    gauge.set(currentValue);
  });
}

export function formatDateYyyyMmDd(dateModifier = 0) {
  const date = new Date();

  if (dateModifier !== 0) {
    date.setDate(date.getDate() + dateModifier);
  }

  const dd = String(date.getDate()).padStart(2, '0');
  const mm = String(date.getMonth() + 1).padStart(2, '0');
  const yyyy = date.getFullYear();

  return `${yyyy}-${mm}-${dd}`;
}

export function chart() {
  moment.locale('fr');

  const chartCanvas = document.querySelectorAll('.js-chart');

  if (chartCanvas.length <= 0) { return; }

  const today = formatDateYyyyMmDd();
  const todayMinusSevenDay = formatDateYyyyMmDd(-7);

  chartCanvas.forEach((chart) => {
    let historyArray = [];
    let datasArray = [];

    const min = +chart.getAttribute('data-chart-min');
    const max = +chart.getAttribute('data-chart-max');
    const history = chart.getAttribute('data-chart-history').split(';').filter(element => element);
    const datas = chart.getAttribute('data-chart-datas').split(';');

    if (history.length > 0) {
      history.forEach((string) => {
        const labelYDataColorArray = string.split(',');
        const label = labelYDataColorArray[0];
        const yData = +labelYDataColorArray[1];
        const color = labelYDataColorArray[2];

        const object = {
        label,
        data: [
          {
            x: today,
            y: yData
          },
          {
            x: todayMinusSevenDay,
            y: yData
          },
        ],
        borderWidth: 3,
        borderColor: color,
        backgroundColor: color,
        pointRadius: 0, pointHitRadius:0
        };

        historyArray = [...historyArray, object];
      });
    }

    datas.forEach((string) => {
      const xDataYDataArray = string.split(',');
      const x = xDataYDataArray[0];
      const y = +xDataYDataArray[1];

      const dateArray = x.split('-');
      const year = dateArray[0];
      const month = +dateArray[1] - 1;
      const day = dateArray[2];

      const date = new Date();
      date.setFullYear(year, month, day);
      date.setHours(0,0,0,0);

      const object = {
        x: date,
        y,
      }

      datasArray = [...datasArray, object];
    });

    new Chart(chart, {
      type: 'line',
      data: {
        datasets: [
          {
            label: 'Niveau d’eau',
            data: [
              ...datasArray
            ],
            borderWidth: 3,
            borderColor: '#115564',
            backgroundColor: '#115564',
          },
          ...historyArray
        ],
      },
      options: {
        maintainAspectRatio: false,
        scales: {
          x: {
            type: 'time',
            max: today,
            min: todayMinusSevenDay,
            time: {
              unit: 'day',
              displayFormats: {
                quarter: 'MM YYYY'
              }
            },
            grid: {
              display: false
            },
            beginAtZero: true
          },
          y: {
            max,
            min,
            ticks: {
              stepSize: 0.5,
              callback: (value, index, ticks) => {
                return `${value} m`;
              }
            },
            beginAtZero: true
          }
        },
        plugins: {
          tooltip: {
            callbacks: {
              title: (context) => {
                const d = new Date(context[0].parsed.x);
                const formattedDate = moment(d).format('DD MMM YYYY')
                return formattedDate;
              },
              label: function(context) {
                return `${context.dataset.label}: ${context.formattedValue} mètres`
              }
            }
          },
          legend: {
            position: 'bottom',
            align: 'start',
            labels: {
              padding: 30,
              boxHeight: 3
            }
          }
        },
        elements: {
          point:{
            radius: 2
          }
        }
      }
    });
  });
}
