//-----------------\\
//--- Les views ---\\
//-----------------\\

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Ce fichier appelle le bon javascript pour chaque page web
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// Importation ----------------------------------------------------------------------------------------------------
import LazyLoad from 'vanilla-lazyload'
import { SCROLLFIRE, PARALLAX } from './main.js'
import { adjustButtonsMargins, addSpanToButtons, addSvgToLinks, calendar, newsImages, manageNavTrack, changeSelect, activeFilter, alerts, initSearchInput, initZoom, newsPushCateg, newsLoaded, slugifyProvider, dragAndSlide, removeHoverOnMobile, changeVariableVhCss, removeBackgroundFixedSection, manageSliderHeight, wrapYtVideo, manageCredit, refreshLazyLoad, resetAssistantSlider, resetAssistantAccordions, formSearchByAddressAutocomplete, adresseSearchResultsLoaded, dynamicPackage, clickToScrollToBlock, lazyloadImages, initButtonsVisibility, manageContactCta, gauge, chart } from './functions/functions.js'
import { fileUpload, select, textareaHeight, clearInput, formSearch, formCarreer, formContact, inputsAndTextareaLabel, formSearchByAddress } from './functions/form.js'
import { slickLogosFooter, slickHomeBanner, slickEvents, slickAlerts, slickAssistant } from './functions/sliders.js'
import { overlayDropdown, overlayMenu, overlaySearch, overlayCalendar, overlayShare, overlayPopup, overlayFastLinks } from './functions/overlays.js'
import { Accordions } from './functions/accordions.js'
import { TyperSetup } from './../libraries/typer.js'
import { googleMap, mapPinMobile, destroyMap, overlayLocation, overlayLocations, goBackMap, subMenu, drawers } from './functions/map.js'
import { isMobile, sizeTitle, sizeTitleDetail } from './functions/helper.js'
//-----------------------------------------------------------------------------------------------------------------

window.newsImages = newsImages
window.slickEvents = slickEvents
window.activeFilter = activeFilter
window.newsPushCateg = newsPushCateg
window.newsLoaded = newsLoaded
window.select = select
window.changeSelect = changeSelect
window.slugifyProvider = slugifyProvider
window.refreshLazyLoad = refreshLazyLoad
window.resetAssistantSlider = resetAssistantSlider
window.resetAssistantAccordions = resetAssistantAccordions
window.adresseSearchResultsLoaded = adresseSearchResultsLoaded
window.dynamicPackage = dynamicPackage
window.lazyloadImages = lazyloadImages

// Initialisation sur toutes les pages
export class View {
  initEssential() {
    dynamicPackage()

    clearInput()
    inputsAndTextareaLabel()

    initZoom()
    initButtonsVisibility()

    removeHoverOnMobile()

    initSearchInput()
    TyperSetup()

    formSearch('#form-search', '#submit-search')
    formSearch('#form-search-overlay', '#submit-search-overlay')

    overlayDropdown()
    overlayMenu()
    overlaySearch()
    overlayShare()
    overlayPopup()
    overlayFastLinks()

    alerts()
    slickAlerts()
    slickLogosFooter()

    gauge();
    chart();

    clickToScrollToBlock({ selector: '.js-scroll-to-body', scrollTo: 'body', offset: 0 })

    if (document.querySelector('#sopfeu-widget-container')) {
      window.build.global.set()
      window.build.create()
    }

    //SCROLLFIRE.init()
    //window['lazyload'] = new LazyLoad()
  }
}


// Classe initialisant les scripts présents dans la page d'accueil
export class Home extends View {
  init() {
    this.initEssential()
    sizeTitle({ selectors: '.js-home-slider-title', caract01: '45' })
    slickHomeBanner()
    newsImages()
    slickEvents()
    changeSelect('#news-home-select')
    calendar(true)

    overlayCalendar()
    select()

    removeBackgroundFixedSection()

    clickToScrollToBlock({ selector: '.js-scroll-to-news', scrollTo: '.js-featured-news', offset: 0 })

    if (!isMobile()) {
      PARALLAX.init([
        {
          target: '.js-home-map-text-parallax',
          parallax: [0, 0, 0.12, -0.12, 0, 1]
        },
        {
          target: '.js-home-map-parallax-1',
          parallax: [0, 0, 0.4, -0.4, 0, 1]
        },
        {
          target: '.js-home-map-parallax-2',
          parallax: [0, 0, 0.4, -0.4, 0, 1]
        },
        {
          target: '.js-home-map-parallax-3',
          parallax: [0, 0, 0.4, -0.4, 0, 1]
        },
      ])
    }

    window['lazyload'] = new LazyLoad()
    SCROLLFIRE.init()

  }
}


// Classe initialisant les scripts présents dans la page de la s'établir à Blainville
export class Generic extends View {
  init() {
    this.initEssential()
    new Accordions()
    sizeTitleDetail()

    manageCredit()

    // Formulaires dynamiques et hardcodés
    fileUpload()
    select()
    textareaHeight()

    calendar()
    //manageNavTrack()
    formCarreer()

    window['lazyload'] = new LazyLoad()
    SCROLLFIRE.init()
  }
}


// Classe initialisant les scripts présents dans la page de liste de nouvelles
export class News extends View {
  init() {
    this.initEssential()
    select()
    changeSelect('#news-select')
    dragAndSlide({ name: 'filter' })

    window['lazyload'] = new LazyLoad()
    SCROLLFIRE.init()
  }
}


// Classe initialisant les scripts présents dans la page de détail de nouvelles
export class NewsDetail extends View {
  init() {
    this.initEssential()

    window['lazyload'] = new LazyLoad()
    SCROLLFIRE.init()
  }
}


// Classe initialisant les scripts présents dans la page de liste d'événements
export class Events extends View {
  init() {
    this.initEssential()
    select()
    if(document.querySelector('.filter')) {
      dragAndSlide({ name: 'filter' })
    }

    calendar()
    overlayCalendar()

    window['lazyload'] = new LazyLoad()
    SCROLLFIRE.init()
  }
}


// Classe initialisant les scripts présents dans la page de détail d'événement
export class Event extends View {
  init() {
    this.initEssential()

    window['lazyload'] = new LazyLoad()
    SCROLLFIRE.init()
  }
}


// Classe initialisant les scripts présents dans la page de résulat de recherche
export class Search extends View {
  init() {
    this.initEssential()
    select()
    formSearch('#form-search-page', '#submit-search-page')

    window['lazyload'] = new LazyLoad()
    SCROLLFIRE.init()
  }
}


// Classe initialisant les scripts présents dans la page de liste d'emplois
export class Jobs extends View {
  init() {
    this.initEssential()
    fileUpload()
    formCarreer()

    window['lazyload'] = new LazyLoad()
    SCROLLFIRE.init()

    window['lazyload'] = new LazyLoad()
  }
}


// Classe initialisant les scripts présents dans la page de detail d'emplois
export class Job extends View {
  init() {
    this.initEssential()
    sizeTitleDetail()
    fileUpload()
    formCarreer()
  }
}


// Classe initialisant les scripts présents dans la page contact
export class Contact extends View {
  init() {
    this.initEssential()
    new Accordions()
    textareaHeight()
    formContact()
    manageContactCta()

    window['lazyload'] = new LazyLoad()
    SCROLLFIRE.init()
  }
}


// Classe initialisant les scripts présents dans la carte interactive
export class Map extends View {
  init() {
    overlayLocation()
    overlayLocations()
    goBackMap()
    subMenu()
    drawers()
    googleMap()

    changeVariableVhCss()
  }
}


// Classe initialisant les scripts présents dans l'assistant virtuel
export class Assistant extends View {
  init() {
    slickAssistant()
    new Accordions()
  }
}


// Classe initialisant les scripts présents dans la page section
export class Sections extends View {
  init() {
    this.initEssential()

    window['lazyload'] = new LazyLoad()
  }
}


// Classe initialisant les scripts présents dans la page recherche par adresse
export class SearchByAddress extends View {
  init() {
    this.initEssential()
    new Accordions()
    formSearchByAddress('#form-search-by-address-page', '#submit-search-by-address-page')
    formSearchByAddressAutocomplete() // todo à réactiver pour la dynamisation
  }
}


// Classe initialisant les scripts présents dans la page horaires
export class Schedules extends View {
  init() {
    this.initEssential()
  }
}


// Classe initialisant les scripts présents dans la page de detail d'un horaire
export class Schedule extends View {
  init() {
    this.initEssential()
  }
}
