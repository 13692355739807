// |--------------------------------------------------------------------------
// | Sliders
// |--------------------------------------------------------------------------
// |
// | Création de sliders.
// |--------------------------------------------------------------------------

import { OBSERVER } from './../main.js'
import { lazyloadImages } from './functions.js'

let assistantSlider
export {assistantSlider}

export const slickLogosFooter = (root = document) => {

  $(root).find('.js-slick-logos-footer').slick({
    dots: false,
    arrows: false,
    slidesToShow: 7,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 5,
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  })

}


export const slickHomeBanner = (root = document) => {

  $(root).find('.js-home-banner-images').slick({
    dots: true,
    arrows: false,
    slidesToShow: 1,
    speed: 500,
    appendDots: '.js-home-slider-dots',
    autoplay: true,
    autoplaySpeed: 6000,
    pauseOnHover: false,
    adaptiveHeight: false,
    asNavFor: '.js-home-banner-texts',
  })

  $(root).find('.js-home-banner-texts').slick({
    dots: false,
    arrows: false,
    slidesToShow: 1,
    fade: true,
    speed: 500,
    pauseOnHover: false,
    adaptiveHeight: false,
    asNavFor: '.js-home-banner-images',
  })

  adjustBannersHeight()
  function adjustBannersHeight(e){
    const headerHeight = document.querySelector('header').offsetHeight
    const windowHeight = window.innerHeight
    document.querySelector('.js-home-banner-container').style.height = (windowHeight - headerHeight) + 'px'
  }
  OBSERVER.add({
    name: 'adjustBannersHeight',
    event: 'resize',
    target: 'window',
    function: adjustBannersHeight
  })
  OBSERVER.on('adjustBannersHeight')

  function toggleCredit(e){
    e.target.classList.toggle('credit--mobile-toggle-on')
  }
  OBSERVER.add({
    name: 'toggleCredit',
    event: 'click',
    target: '.js-toggle-credit',
    function: toggleCredit
  })
  OBSERVER.on('toggleCredit')

}

export const slickEvents = () => {

  $('.js-events-slider').slick({
    dots: false,
    arrows: true,
    appendArrows: '.js-featured-events-nav',
    prevArrow: '<div class="slider-nav__prev"><svg role="img"><use xlink:href="/themes/init-theme/assets/medias/images/icons/symbols.svg#ico-arrow"></use></svg></div>',
    nextArrow: '<div class="slider-nav__next"><svg role="img"><use xlink:href="/themes/init-theme/assets/medias/images/icons/symbols.svg#ico-arrow"></use></svg></div>',
    slidesToShow: 1,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: false,
    variableWidth: true,
    swipe: true,
  })
  lazyloadImages();

}


// Création du slick de la bannière
export function slickAlerts() {
  if (document.querySelectorAll('.js-alerts-slider').length != 0) {

    $(document).find('.js-alerts-slider').slick({
      arrows: true,
      dots: false,
      infinite: true,
      autoplay: true,
      draggable: true,
      speed: 500,
      autoplaySpeed: 5000,
      slidesToShow: 1,
      appendArrows: '.js-alerts-arrow',
      prevArrow: '<div class="alerts__prev"><svg role="img"><use xlink:href="/themes/init-theme/assets/medias/images/icons/symbols.svg#ico-pointer"></use></svg></div>',
      nextArrow: '<div class="alerts__next"><svg role="img"><use xlink:href="/themes/init-theme/assets/medias/images/icons/symbols.svg#ico-pointer"></use></svg></div>',
    })

    let total = $(document).find('.js-alerts-slider .slick-slide:not(.slick-cloned)').length
    $(document).find('.js-alerts-total').html(total)

    $(document).find('.js-alerts-slider').on('beforeChange', function(event, slick, currentSlide, nextSlide){
      var slidePosition = nextSlide + 2 < 10 ? + (nextSlide + 1) : (nextSlide + 1)
      $(document).find('.js-alerts-current').html(slidePosition)
    })
  }
}


export const slickAssistant = (root = document) => {

  assistantSlider = $(root).find('.js-assistant-slider')

  assistantSlider.slick({
    dots: false,
    arrows: true,
    appendArrows: '.js-assistant-nav',
    prevArrow: '<div class="slider-nav__prev"><svg role="img"><use xlink:href="/themes/init-theme/assets/medias/images/icons/symbols.svg#ico-arrow"></use></svg></div>',
    nextArrow: '<div class="slider-nav__next"><svg role="img"><use xlink:href="/themes/init-theme/assets/medias/images/icons/symbols.svg#ico-arrow"></use></svg></div>',
    slidesToShow: 3,
    speed: 500,
    autoplay: false,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  })

}
