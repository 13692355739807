// |--------------------------------------------------------------------------
// | Helper
// |--------------------------------------------------------------------------
// |
// | Les fonctions réutilisable qui ne changeront jamais d'un site à un autre.
// |--------------------------------------------------------------------------

// Ajoute un target blank sur tous les liens externes et PDF
// Empêche une transition si la destination est la page actuelle
export function forceBlankOnExterneAndPdfLinks() {
  let i
  const anchors = document.querySelectorAll('a[href]')
  for (i=0; i<anchors.length; i++) {
    if (anchors[i].target != '_blank' && anchors[i].href != 'javascript:;') {
      if (window.location.hostname !== anchors[i].hostname || anchors[i].href.match('\\.pdf$') || window.location.protocol !== anchors[i].protocol)
        anchors[i].setAttribute('target', '_blank')
    }
  }
}


// Retourne la position d'un élément dans le DOM
export const getElementOffset = (options) => {
  let box = typeof options.element === 'string' ? document.querySelector(options.element).getBoundingClientRect() : options.element.getBoundingClientRect()

  let body = document.body
  let docEl = document.documentElement

  let scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop
  let scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft

  let clientTop = docEl.clientTop || body.clientTop || 0
  let clientLeft = docEl.clientLeft || body.clientLeft || 0

  let top  = box.top +  scrollTop - clientTop
  let left = box.left + scrollLeft - clientLeft

  return { top: Math.round(top), left: Math.round(left) }
}


// Retourne le nombre d'octets formaté
export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 ko'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Octets', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}


// Détecte si un élément est visible dans le viewport et retourne une condition
export function isVisible(element) {
  const rect = element.getBoundingClientRect()

  const viewportHeight = window.innerHeight || document.documentElement.clientHeight
  const viewportWidth = window.innerWidth  || document.documentElement.clientWidth

  const top = rect.top <= viewportHeight
  const right = rect.right >= 0 - viewportWidth
  const bottom = rect.bottom >= 0 - viewportHeight
  const left = rect.left <= viewportWidth

  return top && right && bottom && left
}


// Retourne si l'utilisateur est sur mobile ou pas
export const isMobile = () => {
  let isMobile = false

  if ((/Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)))
    isMobile = true

  return isMobile
}


// Configurer un cookie
export function setCookie(name, value, exdays='') {
  let date = new Date()
  date.setTime(date.getTime() + (exdays*24*60*60*1000))
  let expires = 'expires=' + date.toUTCString()
  document.cookie = name + '=' + value + ';' + expires + ';path=/'
}


// Recevoir un cookie
export function getCookie(cookie) {
  let name = cookie + '='
  let decodedCookie = decodeURIComponent(document.cookie)
  let ca = decodedCookie.split(';')
  let i
  for(i=0; i<ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) == ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) == 0)
      return c.substring(name.length, c.length)
  }
  return ''
}


// Réduit la taille des H1 s'il comporte trop de caractères
export function sizeTitle(object) {
  // Déclaration des propriétés par défaut
  const options = {}

  options.selectors = object.selectors === undefined ? 'h1, .h1' : object.selectors
  options.caract01 = object.caract01 === undefined ? Infinity : object.caract01
  options.caract02 = object.caract02 === undefined ? Infinity : object.caract02
  options.caract03 = object.caract03 === undefined ? Infinity : object.caract03
  options.classe01 = object.classe01 === undefined ? 'little-smaller' : object.classe01
  options.classe02 = object.classe02 === undefined ? 'smaller' : object.classe02
  options.classe03 = object.classe03 === undefined ? 'much-smaller' : object.classe03

  let i
  const selectors = document.querySelectorAll(options.selectors)
  let textLength

  for (i = 0; i < selectors.length; i += 1) {
    textLength = selectors[i].textContent.length

    if (textLength >= options.caract01 && textLength < options.caract02) {
      selectors[i].classList.add(options.classe01)
    } else if (textLength >= options.caract02 && textLength < options.caract03) {
      selectors[i].classList.add(options.classe02)
    } else if (textLength >= options.caract03) {
      selectors[i].classList.add(options.classe03)
    }
  }
}


// Ajouter une classe si le titre est trop long dans une page détail
export function sizeTitleDetail() {
  let i
  let h1 = document.querySelectorAll('.js-page-top-title')
  let h1Length = h1.length

  for(i=0; i<h1Length; i++) {
    if(h1[i].textContent.length > 30) {
      h1[i].classList.add('smaller')
    }
  }
}