/*
Permet de définir le style visuel de la carte GoogleMap
Ce style est importé dans map.js pour afficher la carte
 */

export let mapStyle = [
  {
  "elementType": "labels.icon",
  "stylers": [
  {
  "visibility": "off"
  }
  ]
  },
  {
  "featureType": "administrative",
  "elementType": "geometry",
  "stylers": [
  {
  "color": "#f4f6f0"
  }
  ]
  },
  {
  "featureType": "landscape.man_made",
  "elementType": "geometry",
  "stylers": [
  {
  "color": "#f4f6f0"
  }
  ]
  },
  {
  "featureType": "landscape.natural",
  "elementType": "geometry",
  "stylers": [
  {
  "color": "#f4f6f0"
  },
  {
  "visibility": "on"
  }
  ]
  },
  {
  "featureType": "poi",
  "elementType": "geometry",
  "stylers": [
  {
  "color": "#c5e9c5"
  }
  ]
  },
  {
  "featureType": "poi",
  "elementType": "labels",
  "stylers": [
  {
  "visibility": "off"
  }
  ]
  },
  {
  "featureType": "poi.park",
  "elementType": "labels",
  "stylers": [
  {
  "visibility": "on"
  }
  ]
  },
  {
  "featureType": "poi.park",
  "elementType": "labels.icon",
  "stylers": [
  {
  "visibility": "off"
  }
  ]
  },
  {
  "featureType": "poi.park",
  "elementType": "labels.text.fill",
  "stylers": [
  {
  "color": "#929296"
  }
  ]
  },
  {
  "featureType": "road.highway",
  "elementType": "geometry.fill",
  "stylers": [
  {
  "color": "#ffffff"
  }
  ]
  },
  {
  "featureType": "road.highway",
  "elementType": "geometry.stroke",
  "stylers": [
  {
  "color": "#dce6e8"
  }
  ]
  },
  {
  "featureType": "road.highway",
  "elementType": "labels.text.fill",
  "stylers": [
  {
  "color": "#6d787e"
  }
  ]
  },
  {
  "featureType": "transit",
  "elementType": "geometry",
  "stylers": [
  {
  "color": "#dce6e8"
  }
  ]
  },
  {
  "featureType": "transit",
  "elementType": "labels",
  "stylers": [
  {
  "visibility": "off"
  }
  ]
  },
  {
  "featureType": "water",
  "elementType": "geometry",
  "stylers": [
  {
  "color": "#add3db"
  }
  ]
  },
  {
  "featureType": "water",
  "elementType": "labels.text",
  "stylers": [
  {
  "color": "#576c70"
  }
  ]
  }
  ]